import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { IPageWillMountHandler, IPropsStore, PageFeatureConfigSymbol, Props } from '@wix/thunderbolt-symbols'
import { VerticalMenuPageConfig } from './types'
import { name } from './symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'

const updateComponentsProps = (menusIds: Array<string>, currentPageHref: string, propsStore: IPropsStore) => {
	const updatedProps = menusIds.reduce(
		(updatedObject, menuId) => ({
			...updatedObject,
			...{
				[menuId]: {
					currentPageHref,
				},
			},
		}),
		{}
	)

	propsStore.update(updatedProps)
}

const verticalMenuFactory = (
	pageFeatureConfig: VerticalMenuPageConfig,
	propsStore: IPropsStore,
	urlHistoryManager: IUrlHistoryManager
): IPageWillMountHandler => {
	return {
		pageWillMount: async () => {
			const relativeUrl = urlHistoryManager.getRelativeUrl()
			updateComponentsProps(pageFeatureConfig.componentsIds, relativeUrl, propsStore)
		},
	}
}

export const VerticalMenu = withDependencies(
	[named(PageFeatureConfigSymbol, name), Props, UrlHistoryManagerSymbol],
	verticalMenuFactory
)
